<template>
 <div id="SharedHosting">
  <Navigation/>
  <div class="header-sharedHosting">
   <b-container>
    <b-row>
     <b-col xl="6" md="12">
      <div class="container-title">
       <h2>SHARED HOSTING</h2>
       <p>
        Get our most affordable, reliable, cheap ssd web hosting services.
        Imporve your website's performance quick and easy.
       </p>
      </div>
     </b-col>
     <b-col xl="6" md="12">
      <img
       src="../../assets/SharedHosting/SharedHosting.png"
       alt="SharedHosting"
      />
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div id="whyShaerdHosting">
   <b-container>
    <b-row>
     <div class="text-center title">
      <h2>WHY SHARED HOSTING</h2>
     </div>
     <b-col lg="4" md="6" sm="1">
      <div class="box-container">
       <i class="icon fa fa-desktop"></i>
       <h4>Softaculous</h4>
       <p>
        Softaculous is a fastest Auto Installer having 500+ great scripts with
        daily script updates.
       </p>
      </div>
     </b-col>
     <b-col lg="4" md="6" sm="1">
      <div class="box-container">
       <i class="icon fa fa-cloud"></i>
       <h4>Storage Cloud</h4>
       <p>
        The OS for security and efficiency in a shared hosting environment and
        the ultimate, seven-layer security.
       </p>
      </div>
     </b-col>
     <b-col lg="4" md="6" sm="1">
      <div class="box-container">
       <i class="icon fa fa-lock"></i>
       <h4>Free SSL Certification</h4>
       <p>
        Secure your website with SSL certification. Get free SSL certification
        on every hosting plan.
       </p>
      </div>
     </b-col>
    </b-row>
   </b-container>
  </div>
  <div id="container-table-pricing">
   <div class="header-table-price">
    <b-container>
     <b-row>
      <b-col>
       <div class="text-title">
        <p>FLAT 10% FOR LIFE TIME USE PROMO</p>
        <h2>SHARED HOSTING</h2>
       </div>
      </b-col>
     </b-row>
    </b-container>
   </div>
   <div class="body-table-price">
    <b-container class="container-table-price">
     <b-row>
      <b-col lg="3" class="container-packeg">
       <div class="name-packeg">
        <h2>BASIC<span>(Month)</span></h2>
       </div>
       <div class="img">
        <img
         src="../../assets/SharedHosting/pricing-table-img-one.png"
         alt=""
        />
       </div>
       <div class="price">
        <p>60.<span>DLY</span></p>
       </div>
       <div class="packegs">
        <ul>
         <li>Disk Space 5GB</li>
         <br />
         <li>Bandwidth 30 GB</li>
         <br />
         <li>Emails Account 7 Emails</li>
         <br />
         <li>Mysql Database 2</li>
         <br />
         <li>FTP Users Free</li>
         <br />
         <li>Add-on Domains Free</li>
         <br />
         <li>SSL Certificate Available</li>
         <br />
         <li>DNS Managament</li>
         <br />
         <li>Technical Support</li>
         <br />
        </ul>
       </div>
       <!--        <div class="AdvanceFeatures">
        <div class="title-advance">
         <h3>Advance Features</h3>
         <i></i>
        </div>
        <ul>
         <li>Backup (Websites and Emails) Optional</li>
         <br />
         <li>Dedicated IP Optional</li>
         <br />
         <li>Website and domain Transfer Optional</li>
         <br />
        </ul>
       </div> -->
       <div class="btnBuyNew">
        <div class="btnPay"><button> <router-link to="/cart" class="link">Purchase Now</router-link> </button></div>
        <p><router-link to="/compare-plans">Compare Plans</router-link></p>
       </div>
      </b-col>
      <b-col lg="3" class="container-packeg">
       <div class="name-packeg">
        <h2>BRONZE<span>(2-Month)</span></h2>
       </div>
       <div class="img">
        <img
         src="../../assets/SharedHosting/pricing-table-img-tow.png"
         alt=""
        />
       </div>
       <div class="price">
        <p>90.<span>DLY</span></p>
       </div>
       <div class="packegs">
        <ul>
         <li>Disk Space 5GB</li>
         <br />
         <li>Bandwidth 30 GB</li>
         <br />
         <li>Emails Account 7 Emails</li>
         <br />
         <li>Mysql Database 2</li>
         <br />
         <li>FTP Users Free</li>
         <br />
         <li>Add-on Domains Free</li>
         <br />
         <li>SSL Certificate Available</li>
         <br />
         <li>DNS Managament</li>
         <br />
         <li>Technical Support</li>
         <br />
        </ul>
       </div>
       <!--        <div class="AdvanceFeatures">
        <div class="title-advance">
         <h3>Advance Features</h3>
         <i></i>
        </div>
        <ul>
         <li>Backup (Websites and Emails) Optional</li>
         <br />
         <li>Dedicated IP Optional</li>
         <br />
         <li>Website and domain Transfer Optional</li>
         <br />
        </ul>
       </div> -->
       <div class="btnBuyNew">
        <div class="btnPay"><button>  <router-link to="/cart" class="link">Purchase Now</router-link></button></div>

        <p><router-link to="/compare-plans">Compare Plans</router-link></p>
       </div></b-col
      >
      <b-col lg="3" class="container-packeg-deffrent">
       <div class="name-packeg">
        <h2>SILVER<span>(Yearly)</span></h2>
       </div>
       <div class="img">
        <img
         src="../../assets/SharedHosting/pricing-table-img-three.png"
         alt=""
        />
       </div>
       <div class="price">
        <p>149.<span>DLY</span></p>
       </div>
       <div class="packegs">
        <ul>
         <li>Disk Space 5GB</li>
         <br />

         <li>Bandwidth 30 GB</li>
         <br />

         <li>Emails Account 7 Emails</li>
         <br />

         <li>Mysql Database 2</li>
         <br />

         <li>FTP Users Free</li>
         <br />

         <li>Add-on Domains Free</li>
         <br />

         <li>SSL Certificate Available</li>
         <br />

         <li>DNS Managament</li>
         <br />

         <li>Technical Support</li>
         <br />
        </ul>
       </div>
       <!--        <div class="AdvanceFeatures">
        <div class="title-advance">
         <h3>Advance Features</h3>
         <i></i>
        </div>
        <ul>
         <li>Backup (Websites and Emails) Optional</li>
         <br />
         <li>Dedicated IP Optional</li>
         <br />
         <li>Website and domain Transfer Optional</li>
         <br />
        </ul>
       </div> -->
       <div class="btnBuyNew">
        <div class="btnPay">
         <button class="active-color-bg">  <router-link to="/cart" class="link">Purchase Now</router-link></button>
        </div>
        <p><router-link to="/compare-plans">Compare Plans</router-link></p>
       </div></b-col
      >
      <b-col lg="3" class="container-packeg">
       <div class="name-packeg">
        <h2>GOLD<span>(2-year)</span></h2>
       </div>
       <div class="img">
        <img
         src="../../assets/SharedHosting/pricing-table-img-four.png"
         alt=""
        />
       </div>
       <div class="price">
        <p>198.<span>DLY</span></p>
       </div>
       <div class="packegs">
        <ul>
         <li>Disk Space 5GB</li>
         <br />
         <li>Bandwidth 30 GB</li>
         <br />
         <li>Emails Account 7 Emails</li>
         <br />
         <li>Mysql Database 2</li>
         <br />
         <li>FTP Users Free</li>
         <br />
         <li>Add-on Domains Free</li>
         <br />
         <li>SSL Certificate Available</li>
         <br />
         <li>DNS Managament</li>
         <br />
         <li>Technical Support</li>
         <br />
        </ul>
       </div>
       <!--        <div class="AdvanceFeatures">
        <div class="title-advance">
         <h3>Advance Features</h3>
         <i></i>
        </div>
        <ul>
         <li>Backup (Websites and Emails) Optional</li>
         <br />
         <li>Dedicated IP Optional</li>
         <br />
         <li>Website and domain Transfer Optional</li>
         <br />
        </ul>
       </div> -->
       <div class="btnBuyNew">
        <div class="btnPay"><button>  <router-link to="/cart" class="link">Purchase Now</router-link></button></div>
        <p><router-link to="/compare-plans">Compare Plans</router-link></p>
       </div></b-col
      >
     </b-row>
    </b-container>
   </div>
  </div>
  <Partners />
  <Footer />
 </div>
</template>

<script>
import Partners from "../../../home/components/Partners.vue";
import Footer from "../../../home/components/Footer.vue";
import Navigation from "../../../home/components/Navigation.vue";
export default {
 name: "SharedHosting",
 components: {
    Partners,
    Footer,
    Navigation
},
 data() {
  return {
   checked: false,
  };
 },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Overpass:wght@700&display=swap");
#SharedHosting {
 .container-table-price {
  border-style: solid;
  border-width: 2px;
  border-color: #29ffc6;
  border-radius: 8px;
  box-shadow: 0px 7px 61px 0px #10eae017;
  border-radius: 12px;
  padding: 25px 0;
 }
 .header-sharedHosting {
  position: relative;
  background-color: #0e0b2b;
  background-size: cover;
  padding: 120px 0;
  .container-title {
   padding: 65px 0 0 0;
   h2 {
    font-weight: 800;
    font-size: 40px;
    margin-top: 0;
    font-family: "Overpass", sans-serif;
   }
   p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 30px;
    color: #ffffff;
    transition: 0.2s linear;
    font-family: "Montserrat", sans-serif !important;
   }
  }
  img {
   width: 100%;
  }
 }
 .header-sharedHosting::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #ffffff;
  clip-path: polygon(100% 69%, 0 100%, 100% 104%);
 }
  #whyShaerdHosting {
  padding: 70px 0 70px 0;
  background-color: #fff;
  display: flex;
  .title {
   margin: 28px 0;
   h2 {
    font-size: 36px;
    font-family: "Overpass", sans-serif;
    color: #1c2746;
   }
  }
  .box-container {
   width: 350px;
   text-align: center;
   padding: 0 26px 20px;
   border-radius: 15px;
   margin: 0 15px;
   background: transparent;
   border: 2px solid #ccc;
   box-shadow: 10px 10px 0px #ccc;
   .icon {
    font-size: 65px;
    margin: 50px 0 30px;
    color: #1c2746;
   }
   h4 {
    color: #1c2746;
    font-family: "Overpass", sans-serif;
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 15px;
   }
   p {
    color: #1c2746;
    font-size: 16px;
    line-height: 25px;
    font-family: "Montserrat", sans-serif !important;
    a {
     text-decoration: none;
     color: #1c2746;
     font-size: 16px;
    }
   }
  }
 }

 #container-table-pricing {
  color: #fff;
  background-color: #0e0b2b;
  .header-table-price {
   margin-bottom: 35px;
   padding: 35px 0;
   background-color: #0e0b2b88;
   .text-title {
    display: flex;
    flex-flow: column;
    margin: 45px 0 25px 0;

    p {
     font-size: 14px;
     font-family: "Montserrat", sans-serif !important;
     font-weight: 600;
     color: #ffbe3d;
     text-align: center;
     padding: 0;
     margin-bottom: 3px;
    }
    h2 {
     font-size: 36px;
     color: blanchedalmond;
     font-weight: 900;
     font-family: "Overpass", sans-serif;
     text-align: center;
    }
   }
   .container-img-packeg {
    display: flex;
    flex-flow: row-reverse;
    img {
     width: 200px;
     height: 200px;
    }
   }
  }
  .body-table-price {
   background-image: url("../../assets/SharedHosting/pricing-table-img-background.jpg");
   background-repeat: no-repeat;
   background-size: cover;
   padding: 24px;
   .container-packeg-deffrent {
    background-color: transparent;
    background-image: linear-gradient(180deg, #483dbb -30%, #272546 80%);
    border-style: solid;
    border-width: 2px;
    border-color: #29ffc6;
    border-radius: 8px;
    box-shadow: 0px 7px 61px 0px #10eae045;
    border-radius: 12px;
    padding: 25px 0;
   }
   .container-packeg {
    padding: 25px 0;
   }
   .name-packeg {
    h2 {
     font-family: "Montserrat", sans-serif !important;
     font-size: 26px;
     color: blanchedalmond;
     font-weight: 600;
     text-align: center;
     margin-bottom: 24px;
     padding-top: 8px;
     span {
      padding-top: 10px;
      font-family: "Overpass", sans-serif;
      color: #2ec1ff;
      font-size: 26px;
      display: block;
     }
    }
   }
   .img {
    height: 134px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;
    img {
     margin: 0;
    }
   }
   .price {
    text-align: center;
    p {
     font-family: "Libre Baskerville", serif;
     font-weight: 700;
     color: #2ec1ff;
     font-size: 28px;
     span {
      font-family: "Overpass", sans-serif;
      color: #2ec1ff;
      font-size: 28px;
     }
    }
   }
   .packegs {
    ul {
     margin: 0;
     padding: 0;
     li {
      list-style: none;
      text-align: center;
      font-family: "Montserrat", sans-serif !important;
      font-size: 16px;
      text-transform: capitalize;
      margin: 0;
      padding: 0;
     }
    }
   }
   .AdvanceFeatures {
    margin: 25px;
    .title-advance {
     h3 {
      font-family: "Montserrat", sans-serif !important;
      font-size: 26px;
      color: #2ec1ff;

      font-weight: 600;
      text-align: center;
      margin-bottom: 24px;
      padding-top: 8px;
     }
    }
    ul {
     margin: 0;
     padding: 0;
     li {
      list-style: none;
      text-align: center;
      font-family: "Montserrat", sans-serif !important;
      font-size: 16px;
      text-transform: capitalize;
      margin: 0;
      padding: 0;
     }
    }
   }
   .btnBuyNew {
    .btnPay {
     display: flex;
     justify-content: center;
     align-items: center;
     button {
      padding: 12px 40px;
      background-color: transparent;
      border: 2px solid #2ec1ff;
      border-radius: 10px;
 
      .link{
     color: #fff;
     text-decoration: none;
      }
     }
     button:hover {
      background-color: #2ec1ff;
     }
     .active-color-bg {
      background-color: #2ec1ff;
     }
     .active-color-bg:hover {
      background-color: transparent;
     }
    }

    p {
     margin: 12px 0;
     display: block;
     text-align: center;
     a {
      text-align: center;
      text-decoration-color: #2ec0ff7e;
      color: #2ec1ff;
     }
    }
   }
  }
 }
}
</style>
